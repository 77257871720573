import { Divider } from 'antd';
import { useState } from 'react';
import Fade from 'react-reveal/Zoom';
import { useRecoilValueLoadable } from 'recoil';
import { sendData } from '../services/apiCalls';
import { getSocialLinks } from '../states/contact';
import '../styles/footer.scss';

function PageFooter() {
  const data = useRecoilValueLoadable(getSocialLinks);
  const social = data.state === 'hasValue' ? data.contents : {};
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({ show: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await sendData({ data: { email }, url: 'subscribe' });
    if (res.status) {
      setResponse({ status: 'success', message: res.message, show: true });
      setEmail('');
    } else {
      setResponse({
        status: 'warning',
        message: res.message || res,
        show: true,
      });
    }
    setLoading(false);
  };
  return (
    <footer>
      {response.show && (
        <div class={`alert alert-${response.status}`} role='alert'>
          {response.message}
        </div>
      )}
      <Fade right cascade>
        <div className='top row' style={{ background: '#000' }}>
          <Fade right cascade>
            <div className='col mr-4 justify-content-between'>
              <h4 className='text-white'>Customer Support</h4>
              <p>
                If you have any questions or want to know more about Wayabank
                and our services or contact our Customer Support
              </p>
              <div className='contacts row align-items-center'>
                <div className='col-sm-3 col-md-12 col-lg-3 mr-4'>
                  <a href='/contact'>
                    <button type='submit' style={{background: '#ff6700'}} className='orange'>
                      Contact Us{' '}
                      <span>
                        <img src='/rightArr.png' />
                      </span>
                    </button>
                  </a>
                </div>
                <div className='col col-md-12 col-lg-8 row socials align-items-center'>
                  <div className='col ml-4 pt-1 pl-4'>
                    <a href='https://twitter.com/WayabankNg' target='_blank'>
                      <img
                        src='/twitter.png'
                        style={{ maxWidth: '50%' }}
                        alt='twitter'
                      />
                    </a>
                  </div>
                  <div className='col'>
                    <a
                      href='https://www.facebook.com/wayabank?mibextid=LQQJ4d'
                      target='_blank'
                    >
                      <img
                        src='/facebook.png'
                        style={{ maxWidth: '30%' }}
                        alt='facebook'
                      />
                    </a>
                  </div>
                  <div className='col'>
                    <a
                      href='https://www.instagram.com/wayabankofficial'
                      target='_blank'
                    >
                      <img
                        src='/instagram.png'
                        style={{ maxWidth: '55%' }}
                        alt='instagram'
                      />
                    </a>
                  </div>
                  <div className='col d-none'>
                    <a href='/' target='_blank'>
                      <img
                        src='/linkedin.png'
                        style={{ maxWidth: '55%' }}
                        alt='linkedin'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='inputs col pl-4 pt-4 mt-3 ml-4'>
              <p>
                Be the First to Know About our Promotions, Giveaways, and <br />
                Amazing Business Offers
              </p>
              <form onSubmit={handleSubmit}>
                <div className='d-flex align-items-center'>
                  <input
                    className='px-3'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='text'
                    placeholder='Enter your email address'
                  />
                  <button type='submit'>
                    {loading ? 'Loading...' : 'Subscribe'}
                  </button>
                </div>
              </form>
            </div>
          </Fade>
        </div>
        <div className='bottom container-fluid pt-4'>
          {/* <hr className='pt-4 mt-4' /> */}
          <Divider type='horizontal' className='bg-secondary' />
          <div style={{color: '#ff6700'}}>
            {/* <span
             // className='col-sm-12 col-lg-4'
             className='col-2'
            >
              Copyright &copy; Wayabank {new Date().getFullYear()} All rightsreserved
            </span> */}
            <ul
               className='col col justify-content-between'
            >
              <li>
                <a className='col' href='https://wayalinks.com/about'>
                  About Us
                </a>
              </li>
              <li>
                <a className='col' href='https://wayalinks.com/blogs'>
                  Blog
                </a>
              </li>
              <li>
                <a className='col' href='/terms-of-use'>
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a className='col' href='/privacy-page'>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className='col' href='/contact'>
                  Contact Us
                </a>
              </li>
              <li>
                <a className='col' href='https://wayalinks.com/faq'>
                  FAQs
                </a>
              </li>
            </ul>
            <span
             // className='col-sm-12 col-lg-4'
             className='col-2'
            >
              Copyright &copy; Wayabank {new Date().getFullYear()} All rights reserved
            </span>

          </div>
        </div>
      </Fade>
    </footer>
  );
}

export default PageFooter;
